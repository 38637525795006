.plan-buttons-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-top: 1.6rem;
}

.usage-fee-copy {
    margin-top: 1.6rem;
}

.feature-container {
    margin-top: .75rem;
}