.container {
  display: flex;
}

img {
  width: 25%;
  padding-right: 2rem;
  object-fit: contain;
}

.text-container {
  max-width: 75%;
}
