.plan-cards-container {
    margin-top: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.plan-cards-container :global .Polaris-Card {
    width: calc(50% - 1rem);
    padding: 2.5rem;
}

.plan-cards-container :global .Polaris-Card + .Polaris-Card {
    margin-top: unset;
}

.choose-plan-card-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.price-and-action-container {
    flex-basis: 60%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 1.25rem;
}

.price-and-action-container > :first-child {
    font-weight: 500;
}

.base-price-line {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: flex-end;
}

.feature {
    margin: 1rem auto;
}