.welcome-page-container :global .Polaris-DisplayText {
    font-weight: 500;
    font-size: 32px;
    width: 80%;
    margin: 1.5rem auto 2.75rem auto;
    line-height: 40px;
}

.welcome-page-container :global .Polaris-EmptyState__Content > p {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: .75rem;
}

.welcome-page-container :global .Polaris-EmptyState__FooterContent > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: .25rem;
}

.welcome-page-container :global .Polaris-EmptyState__FooterContent > div > button {
    margin-top: .25rem;
}