.wrapper {
  padding: 12rem 4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
}

.wrapper > * {
  padding: 2rem 0;
  max-width: 400px;
}

.wrapper > p {
  font-size: 2rem;
  text-align: center;
  padding: 0;
}